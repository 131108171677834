import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { Link as Scroll } from "react-scroll"

const Features = () => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="职能"
          pagepath="/features"
          pagedesc="职能 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
        />
        <main className="function">
          <section className="function_introduction">
            <div className="function_introduction_container relative">
              <div>
                <h1 className="function_title mb-5">职能</h1>
                <p className="function_lead">
                  这是对PARKLoT功能的介绍，可以让你轻松地开始一个活动。
                  <br />
                  即时中奖、自动抽奖、
                  <br />
                  结果通知、报告功能、
                  <br />
                  <span className="tracking-tight">
                    地址收集以及组织活动所需的其他功能。
                  </span>
                </p>
              </div>
              <div className="function_introduction_img">
                <StaticImage
                  layout="constrained"
                  alt="职能"
                  src="../../images/features/top.png"
                />
              </div>
            </div>
          </section>
          <div className="mobile-spacing"></div>

          <section className="function_btn-background">
            <div className="function_btn_list flex m-auto">
              <Scroll
                className="function_container"
                to="campaign"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        className="features_list_img"
                        layout="constrained"
                        alt="运动创建"
                        src="../../images/features/campaign.png"
                      />
                    </div>
                    <span className="text-base pt-1">运动创建</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="win" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="自动抽奖"
                        src="../../images/features/win.png"
                      />
                    </div>
                    <span className="text-base pt-1">自动抽奖</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="mail" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="结果通知"
                        src="../../images/features/mail.png"
                      />
                    </div>
                    <span className="text-base pt-1">结果通知</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="report" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="报告功能"
                        src="../../images/features/report.png"
                      />
                    </div>
                    <span className="text-base pt-1">报告功能</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll
                className="function_container"
                to="location"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <StaticImage
                        layout="constrained"
                        alt="地址收集"
                        src="../../images/features/location.png"
                      />
                    </div>
                    <span className="text-base pt-1">地址收集</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-10 pt-5 bg-white">
            <p className="md:text-2xl my-1 text-xl">
              ＼ 现在就下载这些文件! ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              下载一份3分钟文件，
              <br />
              了解PARKLoT如何运作（免费的）
            </a>
          </section>

          <section className="function_detail">
            <div id="campaign" className="function_green">
              <h2 className="function_subtitle">从管理屏幕上轻松设置活动。</h2>
              <p className="function_text">
                可以在关注和转发的基础上组织即时获胜活动。
                <br />
                它可以在短短一天内实施。
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="从管理屏幕上轻松设置活动。"
                  src="../../images/features/section01.png"
                />
              </div>
            </div>

            <div id="win" className="function_white">
              <h2 className="function_subtitle">没有繁琐的抽签工作</h2>
              <p className="function_text">
                获奖者将从参赛者中随机抽取。
                <br />
                筛选可以用来缩小追随者的最小数量。
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="没有繁琐的抽签工作"
                  src="../../images/features/section02.png"
                />
              </div>
            </div>

            <div id="mail" className="function_green">
              <h2 className="function_subtitle">彩票结果的自动DM传输</h2>
              <p className="function_text">
                参与者的抽奖结果将通过即时DM自动发送。它也可以在以后的抽奖中分批发送给获奖者。
                <br />
                不需要进行DM传输工作。
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="彩票结果的自动DM传输"
                  src="../../images/features/section03.png"
                />
              </div>
            </div>

            <div id="report" className="function_white">
              <h2 className="function_subtitle">报告功能消除了收集工作</h2>
              <p className="function_text">
                自动收集追随者的数量和申请的信息。自主收集追随者的数量和申请的信息。
                你可以在任何时候从管理界面检查。
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="报告功能消除了收集工作"
                  src="../../images/features/section04.png"
                />
              </div>
            </div>

            <div id="location" className="function_green">
              <h2 className="function_subtitle">地址收集很容易</h2>
              <p className="function_text">
                如果是实物礼品，可以用专门的表格向获奖者登记送货地址。
                <br />
                注册地址也可以以csv格式批量输出。
              </p>
              <div className="function_detail_img m-auto">
                <StaticImage
                  layout="constrained"
                  alt="地址收集很容易"
                  src="../../images/features/section05.png"
                />
              </div>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-20 mt-14 pb-20">
            <p className="md:text-2xl my-1 text-xl">
              ＼ 现在就下载这些文件！ ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              下载一份3分钟文件，
              <br />
              了解PARKLoT如何运作（免费的）
            </a>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Features
